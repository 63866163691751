import * as React from "react"
import styled from "styled-components"

import Header from "./Header"

import { layout, LayoutProps, SpaceProps, space } from "styled-system"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Subscribe from "./Subscribe"

const Wrapper = styled.div<LayoutProps>`
  background-color: rgba(8, 65, 92, 0.9);
  text-align: center;
  position: relative;
  strong {
    color: #fa824c;
    font-weight: normal;
  }
  ${layout}
`

const HeroContent = styled.div<SpaceProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${space};
`

const Title = styled.h1`
  margin: 0 auto 52px auto;
  padding-top: 104px;
  color: white;
  text-align: center;
  max-width: 800px;
  @media screen and (min-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Subtitle = styled.p`
  color: #ccc;
`

const Background = styled(Img)`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default function Hero() {
  const data = useStaticQuery(graphql`
    query HeaderImage {
      headerImage: file(relativePath: { eq: "bg1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Wrapper height={[440, 560]}>
      <Header position="absolute" />
      <Background
        fluid={data.headerImage.childImageSharp.fluid}
        alt="Photo by Simon Migaj on Unsplash"
      />
      <HeroContent paddingTop={[0, 70, 104]}>
        <Title>Ne perdez plus de temps à faire vos plannings !</Title>
        <Subtitle>
          Pharmille simplifie la gestion du personnel de pharmacie
        </Subtitle>
        <Subscribe />
      </HeroContent>
    </Wrapper>
  )
}
