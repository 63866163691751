import * as React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import Sal from "../components/Sal"
import "sal.js/dist/sal.css"

import Subscribe from "../components/Subscribe"

import Theme from "../components/Theme"

// @ts-ignore
import collaboration from "../assets/collaboration.svg"
// @ts-ignore
import responsive from "../assets/responsive.svg"
// @ts-ignore
import time from "../assets/time.svg"
// @ts-ignore
import ChevronIcon from "../assets/chevron.inline.svg"
import Footer from "../components/Footer"

// @ts-ignore
import DrawVideo from "../assets/Pharmille-01.mp4"

import { color } from "styled-system"
import Hero from "../components/Hero"
import { Box } from "@material-ui/core"

const Chevron = styled.div`
  padding-top: 16px;
  svg {
    height: 30px;
  }
  text-align: center;
  color: #fa824c;
`

const Section = styled.div`
  padding: 16px 8px;
  display: flex;
  ${color};
`

const Video = styled.video`
  max-width: 100%;
`

const InnerSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 64px 40px;
  justify-content: space-around;
  max-width: 1080px;
  flex-direction: column;
  @media screen and (min-width: 650px) {
    flex-direction: row;
  }
`

const SectionTitle = styled.h2`
  margin-top: 0;
`

const Text = styled.div`
  flex: 1;
  strong {
    color: #fa824c;
    font-weight: normal;
  }
  margin-bottom: 16px;
  @media screen and (min-width: 650px) {
    padding-right: 8px;
  }
`

const Illustration = styled.div`
  flex: 1;
  text-align: center;
  padding-top: 8px;
`

export default () => {
  React.useEffect(() => {
    require("sal.js")({ once: true })
  }, [])

  return (
    <Theme>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gestion planning personnel pharmacie, Pharmille</title>
        <meta
          name="description"
          content="Pharmille simplifie la gestion du personnel de pharmacie. Fonctionne sur votre tablette ou ordinateur. Comptabilisation des heures sans effort."
        />
      </Helmet>
      <Hero />
      <Chevron>
        <ChevronIcon />
      </Chevron>
      <Section>
        <Sal as={InnerSection} animation="slide-right">
          <Text>
            <SectionTitle>
              <strong>Dessinez</strong> vos planning
            </SectionTitle>
            "Dessinez" sur l'écran vos planning. Les horaires de vos employées
            seront toujours comptabilisés sans erreurs.
          </Text>
          <Illustration>
            <Video playsInline autoPlay loop src={DrawVideo} />
          </Illustration>
        </Sal>
      </Section>
      <Section bg="#eee">
        <Sal as={InnerSection} animation="slide-right">
          <Text>
            <SectionTitle>
              Un outils <strong>adapté</strong> pour toutes{" "}
              <strong>officines</strong>
            </SectionTitle>
            Nous avons collaboré avec plusieurs <strong>pharmaciens</strong>{" "}
            pour obtenir l'outils le plus <strong>adapté</strong> et{" "}
            <strong>performant</strong>.
          </Text>
          <Illustration>
            <img
              width="380px"
              src={collaboration}
              alt="Collaboration illustration"
              data-sal-easing="ease-out-bounce"
            />
          </Illustration>
        </Sal>
      </Section>
      <Section>
        <Sal as={InnerSection} animation="slide-left">
          <Illustration>
            <img width="380px" src={responsive} alt="Responsive illustration" />
          </Illustration>
          <Text>
            <SectionTitle>
              Un outils utilisable <strong>à tous moment</strong>
            </SectionTitle>
            Nous nous assurons d'une utilisation optimisé sur tous vos
            appareils.
          </Text>
        </Sal>
      </Section>
      <Section bg="#eee">
        <Sal as={InnerSection} animation="slide-right">
          <Text>
            <SectionTitle>
              <strong>Gagner</strong> du temps maintenant.
            </SectionTitle>
            <p>Inscrivez vous afin de pouvoir profiter dès que possible.</p>
            <Box textAlign="center">
              <form
                name="subscription"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                hidden
              >
                <input type="email" name="email" />
              </form>
              <Subscribe />
            </Box>
          </Text>
          <Illustration>
            <img
              width="380px"
              src={time}
              alt="Responsive illustration"
              data-sal-easing="ease-out-bounce"
            />
          </Illustration>
        </Sal>
      </Section>
      <Footer />
    </Theme>
  )
}
