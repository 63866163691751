import * as React from "react"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import "react-toastify/dist/ReactToastify.css"

import { toast } from "react-toastify"

declare global {
  interface Window {
    Sentry: any
  }
}

const ButtonWrapper = styled.div`
  position: relative;
`

const MyButton = styled(Button)`
  ${({ success }) =>
    success &&
    `
    background-color: #4caf50;
    :hover {
      background-color: #388e3c;
    }
  `}
`

export default function FormDialog() {
  const [open, setOpen] = React.useState(false)
  const [emailValue, setEmailValue] = React.useState("")

  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  return (
    <>
      <Button
        size="large"
        color="secondary"
        variant="contained"
        onClick={handleClickOpen}
      >
        S'Inscrire !
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form
          method="post"
          name="subscription"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={async (e) => {
            e.preventDefault()
            const form = e.target as HTMLFormElement
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({
                "form-name": form.getAttribute("name"),
                email: emailValue,
              }),
            })
              .then((response) => {
                if (response.status.toString().startsWith("2")) {
                  toast.success(
                    "🎉 Super ! Nous vous contacterons dès que possible."
                  )
                } else {
                  toast.error(
                    "Oups ! Nous avons rencontré un problème, envoyez nous votre email à contact@pharmille.com "
                  )
                  window.Sentry.captureException(
                    new Error(
                      `Subscription failed with the status ${response.status}`
                    )
                  )
                }
                handleClose()
              })
              .catch((e) => {
                window.Sentry.captureException(e)
                toast.error(
                  "Oups ! Nous avons rencontré un problème, envoyez nous votre email à contact@pharmille.com "
                )
                handleClose()
              })
          }}
        >
          <input type="hidden" name="form-name" value="subscribe" />
          <DialogTitle id="form-dialog-title">Inscription</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Soyer prévenus et profitez gratuitement de notre première version.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Adresse Email"
              type="email"
              required
              fullWidth
              onChange={(event) => {
                setEmailValue(event.target.value)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <ButtonWrapper>
              <MyButton type="submit" color="primary">
                S'Inscrire
              </MyButton>
            </ButtonWrapper>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
