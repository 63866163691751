import * as React from "react"

// Don't forget the imports and to initialize
// sal, when the application first mounts.
// Don't do it in this file.

// import sal from 'sal.js';
// import 'sal.js/dist/sal.css';
// sal();

interface Props {
  animation?: string
  as?: any
  children?: React.ReactNode
  duration?: string
  delay?: string
  easing?: string
}

const Sal = ({
  animation = "fade",
  duration = "500",
  delay = "0",
  easing = "ease-out-bounce",
  as = "div",
  children = null,
}: Props) => {
  const Tag = as
  return (
    <Tag
      data-sal={animation}
      data-sal-duration={duration}
      data-sal-delay={delay}
      data-sal-easing={easing}
    >
      {children}
    </Tag>
  )
}

export default Sal
